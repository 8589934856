import * as React from 'react';
import {
    Edit,
    NumberInput,
    useTranslate,
    SimpleForm,
    BooleanInput,
} from 'react-admin';
import { Grid, Typography, Box } from '@mui/material';
import SegmentsFormToolbar from './SegmentsFormToolbar';
import { RichTextInput } from '../components/RichTextInput';
import CalculationInsurancePrices from './calculation_insurance_prices';

const CalculatorForm = () => {
    const translate = useTranslate();
    const formDefaults = {
        multiple: true,
        method: 'post',
    };
    const InsuranceList = CalculationInsurancePrices.list;

    return (
        <Edit resource="settings" redirect={false} mutationMode="pessimistic">
            <SimpleForm
                defaultValues={formDefaults}
                toolbar={<SegmentsFormToolbar />}
            >
                <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        minWidth={{ xs: '100%', xl: '80%' }}
                    >
                        <Typography variant="h4" gutterBottom>
                            {translate(
                                'resources.settings.fieldGroups.calculator'
                            )}
                        </Typography>
                        <Box>
                            <BooleanInput
                                source="Logistics__map_inputs_enabled"
                                label="resources.settings.values.logistics__map_inputs_enabled"
                                fullWidth
                            />
                        </Box>
                        <RichTextInput
                            source="ApiLogix__calculator_no_response_error"
                            label="resources.settings.values.api_logix__calculator_no_response_error"
                            fullWidth
                        />
                        <RichTextInput
                            source="ApiLogix__order_no_response_error"
                            label="resources.settings.values.api_logix__order_no_response_error"
                            fullWidth
                        />
                        <RichTextInput
                            source="ApiLogix__order_form_message_autoswitch_to_delivery"
                            label="resources.settings.values.api_logix__order_form_message_autoswitch_to_delivery"
                            fullWidth
                        />
                        <NumberInput
                            source="Logistics__calculation_insurance_overflow_percent"
                            label="resources.settings.values.logistics__calculation_insurance_overflow_percent"
                            helperText={translate(
                                'resources.calculation_insurance_prices.helpers.insurance_overflow_percent'
                            )}
                            fullWidth
                            min={0}
                            max={100000}
                            step={0.01}
                        />
                        <Typography variant="h6" gutterBottom marginLeft={2}>
                            {translate(
                                'resources.settings.values.logistics__calculation_insurance_prices'
                            )}
                        </Typography>
                        <InsuranceList />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export default CalculatorForm;
